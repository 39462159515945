import React, {useEffect, useState} from 'react';

function App() {
  const [logged, setLogged] = useState();

  useEffect(() => {
    Check();
  }, [])

  const Login = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('user', 's@s.com');
    bodyFormData.append('password', 'pass123');

    fetch('api/api.php?act=login', {
      method: 'POST',
      body: "user=user@mail.com&password=pass123",
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
    .then(res => res.json()).then(result => {
      if(result.data.userId) {
        setLogged(true);
      } else {
        setLogged(false);
      }
    })
  }

  const Check = () => {
    fetch('api/api.php?act=check', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(res => res.json()).then(result => {
      if(result.data.userId) {
        setLogged(true);
      } else {
        setLogged(false);
      }
    })
  }

  return (
    <div>
      <div class="e">REACT APP + PHP</div>
      {logged && (
        <div>LOGGED!</div>
      )}
      
      {logged === false && (
        <>
          <div><button onClick={Login}>Login</button></div>
        </>
      )}
    </div>
  )
}

export default App
